import React from 'react'

import { getFirebase } from '../services/firebase'

class EmailList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailList: [],
      eventId: props.eventId
    }
  }

  componentDidMount() {
    const lazyApp = import('firebase/app')
    const lazyDatabase = import('firebase/firestore')

    Promise.all([lazyApp,lazyDatabase]).then(([firebase]) => {
      this.firestore = getFirebase(firebase).firestore;
      this.database = getFirebase(firebase).firestore();

      this.database.collection("emails").where("eventId", "==", this.state.eventId)
      .onSnapshot((snapshot) => {
        this.setState({ emailList: snapshot.docs.map((doc) => Object.assign({}, doc.data(), { id: doc.id }) ) })
      });
    })
  }


  render() {
    return (
      <div>
        {this.state.emailList.map((email)=> {
          return <div>{email.email}</div>
        })}
      </div>
    )
  }
}

export default EmailList
