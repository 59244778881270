import React, { useState, useEffect } from 'react';

import { getFireStore } from '../services/firebase'

function EventEmailCapture(props) {
   const [email, setEmail] = useState("");
   const [status, setStatus] = useState("ready");

   useEffect(()=>{
     if(status === 'loading' && email !== ""){
      getFireStore().then((firestore)=>{
        const timestamp = firestore.FieldValue.serverTimestamp();
        firestore().collection("emails").add({
          email: email,
          eventId: props.eventId,
          createdAt: timestamp
        })
        .then((docRef) => {
          setStatus('complete');
        })
        .catch((error) => {
          setStatus('failed');
        });
      });
     }
   },[status])

   if(status === 'complete'){
     return SuccessMessage();
   }

   return EmailInput(status, email, setEmail, setStatus);
}

function SuccessMessage() {
  return   <div
      style={{
        color: 'green',
        marginTop: '10px'
      }}
    >
      <span role="img" aria-label="Burger">🍔</span> All Set. See you soon!  <span role="img" aria-label="Burger">🍔</span> 
    </div>
}

function EmailInput(status, email, setEmail, setStatus) {
  return <div
    style={{
      marginTop: '10px'
    }}
  >
    <div
      className={`control ${status ==='loading' && 'is-loading'}`}
      style={{
        display: 'inline-block'
      }}
    >
      <input
        className="input"
        type="email"
        name="email"
        placeholder="Enter your email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        style={{
          maxWidth: '200px',
          marginRight: '20px',
          top: '-6px'
        }}
      />
    </div>
    <button
      className="btn"
      onClick={() => setStatus('loading')}
      style={{
        padding: '7px 16px 7px',
      }}
    >
        {status === 'failed' ? 'Try Again' : 'RSVP'}
    </button>
  </div>
}
export default EventEmailCapture
