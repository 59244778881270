import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import EventEmailCapture from '../components/EventEmailCapture'
import EmailList from '../components/EmailList'
import Content, { HTMLContent } from '../components/Content'

export const EventPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  location,
  price,
  food,
  shareImage,
  eventDateTime,
  venmoLink,
  helmet,
  eventId,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content"
        style={{
          color: 'black'
        }}
      >
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {shareImage &&
              <Img fluid={shareImage.childImageSharp ? shareImage.childImageSharp.fluid : ""} alt={shareImage.alt} />
            }
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light"
              style={{
                color: 'black'
              }}
            >
              {title}
            </h1>
            <p>
              <span style={{fontWeight: "700"}}>When:</span> {eventDateTime}
              <br/>
              <span style={{fontWeight: "700"}}>Where:</span> {location}
              <br/>
              <span style={{fontWeight: "700"}}>Food:</span> {food}
              <br/>
              <span style={{fontWeight: "700"}}>Cost:</span> {price}
              <br/>
              <span style={{fontWeight: "700"}}>Venmo Link:</span> <a href={venmoLink} target="_blank"  rel="noopener noreferrer">Click Here</a>
              <br/>
              <span style={{fontWeight: "700"}}>Description:</span>{description}
            </p>
            <EventEmailCapture
              eventId={eventId}
            />
            <EmailList
              eventId={eventId}
            />
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

EventPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const EventPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <EventPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Event">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          <meta property="og:title" content="Invite to Burger Night. Click here to RSVP."/>
          <meta property="og:description" content={`${post.frontmatter.description}`}/>
          {post.frontmatter.shareImage &&
            <meta property="og:image" content={`${post.frontmatter.shareImage.childImageSharp.fluid.src}`}/>
          }
          <meta property="og:url" content={`${post.fields.slug}`}/>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        location={post.frontmatter.location}
        price={post.frontmatter.price}
        food={post.frontmatter.food}
        shareImage={post.frontmatter.shareImage}
        eventDateTime={post.frontmatter.eventDateTime}
        venmoLink={post.frontmatter.venmoLink}
        eventId={post.fields.slug}
      />
    </Layout>
  )
}

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default EventPost

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        location
        price
        food
        eventDateTime
        venmoLink
        shareImage{
          childImageSharp {
            fluid(maxHeight: 630, maxWidth: 1200 quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
